

.article{
    background-color: #ffffff;
    min-height: 100vh;
    &__container{
      
    }
    &__title{
        font-size: 34px;
        line-height: 1.17;
        text-align: center;
        color: #130f36;
        padding:40px 20px;
    }
    &__main-img{
        width:100%;
        height:350px;
        object-fit: cover;
    }
    &__info-wrapper{
        padding:20px;
    }
    &__first-paragraph{
        font-size: 18px!important;
        font-size: 20px;
        line-height: 1.3;
        color: #130f36;
    }
    &__paragraf{
        font-size: 16px;
        font-weight: 300;;
        line-height: 1.38;
        color: #0f1017;
    }
    &__subTitle{
        font-size: 22px;
        font-weight: 500;
        line-height: 1.4;
        color: #130f36;
        text-align: center;
        padding:15px 0 0;
    }

    &__image {
        margin:20px 0;
      width: 100%;
    }
    &__share-it{
        padding-top:20px;
    }
    &__share-span{
        color:#01f701;
    }


    &__cta-section {
      margin: 0 20px;
    }

    &__link{
        cursor: pointer;
    }


    @media (min-width:768px){
        &__info-wrapper{
            padding:40px;
        }
        &__title{
            padding-left: 40px;
        }
        &__image{
            max-height: 350px;
            object-fit: cover;
        }
        &__contact-box{
            margin:20px 40px;
        }
    }
    @media (min-width:1024px){
       &__container{
           padding:40px 0;
       }
       &__info-wrapper{
           padding:0 10%;
       }
       &__title{
           text-align: left;
           padding:40px 10%;
           font-size: 56px;
       }
        &__paragraf,&__subTitle{
           text-align: left;
           padding:20px 15%;
       }
       &__subTitle{
           padding-bottom: 10px;
       }

       &__subscribe-box{
           width:80%;
           padding:0 10%;
           margin:0;
       }
       &__image{
           max-height: 575px;
           box-sizing: border-box;
           padding:0 15%;
            display: flex;
            justify-content: center;
            margin:40px auto;
            object-fit: cover;
       }
       &__contact-box{
        margin:20px 22%;
        h4{
            font-size: 18px;
            width:60%;
            margin:20px auto;
        }
        button{
            padding:10px 50px;
            font-size: 16px;
        }
    }
    }
}
