@import '../../resources/media-queries.scss';

.header{
    padding:20px;
    background-color: #ffff;
    &__box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__logo{
        width:80px;
        height:auto;
    }
    &__menu-icon{
        width:30px;

    }
    @include tablet{
        padding:20px 40px;
    }

    @include desktop{
        padding:20px 8%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}