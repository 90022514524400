@import '../../resources/media-queries.scss';

.footer{
    background-color: #b30000;
    
    &__list{
        list-style-type: none;
        padding:20px 0;
        margin:0 auto 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid grey;
        width:90%;
        li{
            font-size: 14px;
            line-height: 2.29;
            color: #ffffff;
        }
    }
    &__sub-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:0 auto;
        align-items: center;
        padding-bottom: 20px;
    }
    &__details{
        font-size: 14px;
        line-height: 2;
        color: #ffffff; 
        font-weight: lighter;
        padding-right: 10px;
    }
    &__facebook{
        width:20px;
        margin:10px 0;
    }
    &__contact{
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
    }
    @include tablet{

    }

    @include desktop{
        &__sub-info{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding:40px 8%;
            margin:0 auto;
            align-items: center;
            padding-bottom: 20px;
        }
        &__facebook{
            position: relative;
            left:28px;
        }
    }
}