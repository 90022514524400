@import '../../resources/media-queries.scss';


    .hamburger{
        &__icon{
            width: 32px;
            position: absolute;
            top: 22px;
            right: 22px;
            cursor: pointer;
        }
        &__close-icon {
            display: none;
            position: absolute;
            width: 22px;
            right: 3%;
            top: 16px;
            z-index: 20;

            &.active {
              display: block;
            }
          }
        &__links {
            list-style: none;
            width: 55%;
            float: right;
            margin-right: 0;
            z-index: 20;
            clear: both;
            overflow: hidden;
            top: 0;
            right: 0;
            padding: 20px;
            height: 100%;
            margin-top: 0;
            background-color: #00f9f7;
            border-right: solid 1px lightgrey;
            position: fixed;
            transform: translateX(100%);
            transition: transform .3s;
            overflow-y: auto;


            &.active {
              transform: translateX(0);
            }
        }
        &__list-item{
            font-family: 'Domine', serif;
            font-size: 17px;
            line-height: 1.64;
            padding:10px;
            border-bottom: 1px solid #e6e6ff;
        }
        &__link--menu{
            font-family: 'Domine', serif;
            font-size: 22px;
            font-weight: bolder;
            margin: 10px 0 20px;
            color: #ffff;
            padding-left: 8px;
        }
        &__link{
            text-decoration: none;
            color:initial;
            color: #ffff;
        }
        &__platform-access{
            background-color: transparent;
            width:100%;
            border: 1px solid #000033;
            margin-top: 20px;
            padding:10px 0;
            cursor: pointer;
        }
        &__platform{
            color:#ffff;
            font-size: 18px;
        }
        &__link-box{
            display: flex;
            flex-direction: row;
            img{
                width:18px;
                height:18px;
                position: relative;
                top:15px;
            }
        }
        &__submenu{
            list-style-type: none;
            padding-left: 0;
            li{
                color:#333333;
                font-size: 14px;
                border-bottom: none;
            }
        }
        @include desktop{
            &__icon{
                display: none;
            }
        }
    }
