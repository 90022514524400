@import '../../resources/media-queries.scss';

.menu{
    display: none;

    @include desktop{
            display: flex;
            justify-content: flex-end;
            align-items: center;

        &__links{
            list-style-type:none;
            display: flex;
            flex-direction: row; 
        }
        &__list-item{
            cursor: pointer;
            padding:0 20px;
            display: flex;
        }
        &__submenu-box{
            display: flex;
            flex-direction: row;
        }
        &__arrow-icon{
            width:12px;
            height:8px;
            position: relative;
            top:8px;
            cursor: pointer;
        }
    }
}