@import './media-queries.scss';

.standard{
    h1, h2, h3, h4, h5, p{
        margin:0;
    }
    h1, h2, h3{
        font-family: 'Domine', serif;
    }

    h4,h5,h6, li, p{
        font-family: 'Cabin', sans-serif;
    }
    &__link{
        text-decoration: none;
        color:initial;
        cursor: pointer;
    }
    button{
        cursor: pointer;
    }
    &__only-desktop{
        display: none;
    }

    @include tablet{

    }
    @include desktop{
        &__only-mobile{
            display: none;
        }
        &__only-desktop{
            display: block;
        }
    }
}