@import '../../resources/media-queries.scss';

.homepage{
    background-color: #f8fcff;
    &__first-section{
        position: relative;

    }
    &__first-img{
        width:100%;
        height:400px;
        object-fit: cover;
    }
    &__title{
        font-size: 30px;
        line-height: 1.22;
        text-align: center;
        padding-bottom: 10px;
        color:black;
        padding-top: 10px;
    }
    &__title-main{
        -webkit-backdrop-filter: blur(10px);
        margin-top: 80px;
        backdrop-filter: blur(1px);
        background-color: rgba(255, 255, 255, 0.5);  
        margin-bottom: 60px;
    }
    &__title-box{
        position: absolute;
        top:20px;
        width:100%;
    }
    &__motto{
        color:#ffffff;
        line-height: 1.22;
        text-align: center;
        font-size: 20px;
    }
    &__about-section{
        padding:20px;
        background-color: #f8fcff;
    }
    &__about-title{
        font-size: 24px;
        font-weight: 900;
        letter-spacing: -0.52px;
        color: #051f61;
        padding-bottom: 10px;
    }
    &__about-info{
        font-size: 16px;
        line-height: 1.5;
        color: #051f61;
    }
    &__security-details{
        padding:20px;
    }
    &__section-title{
        font-size: 24px;
        font-weight: 500;
        color: #1f2120;
    }
    &__section-info{
        font-size: 16px;
        line-height: 1.67;
        color: #646666;
    }
    &__section-img{
        width:100%;
        height:300px;
        object-fit: cover;
    }
    &__services-box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &__link{
        width:50%;
    }
    &__individual-service{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:20px auto;
        
        cursor: pointer;
    }
    &__service-img{
        width:60%;
        height:100px;
        object-fit: cover;
        border-radius: 50%;
    }
    &__service-name{
        padding:10px 0 0;
        color: #00f9f7;
        font-size:18px;
        letter-spacing: -0.52px;
        width:60%;
        text-align: left;
    }
    &__home-img{
        width:100%;
        padding:20px 0;
    }
    &__home-section{
        background-color: #f8fcff;
        padding:20px 0;
        h3, h4{
            padding:0 20px;
        }
    }
    &__fire-box{
        padding:0 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__card-link{
        width:45%;
    }
    &__card{
        margin:20px 0;
    }
    &__fire-img{
        width:100%;
        height:100px;
        object-fit: cover;
    }
    &__card-info{
        background-color: #ffff;
        padding:10px 10px 0;
        margin:20px 0;
        box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
        cursor: pointer;
        min-height: 60px;
    }
    &__it-section{
        background-image:url('/images/it.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding:40px;
        justify-content: space-evenly;
    }
    &__it-services{
        background-color: transparent;
        border:1px solid #fff;
        box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
        cursor: pointer;
        width:auto;
        padding:10px;
        margin:10px!important;
        font-size: 16px;
        line-height: 1.31;
        color: #fff;
        border-radius: 5px;
    }
    &__subtitle-info{
        padding-bottom: 20px!important;
    }
    &__subtitle{
        background-color: #f8fcff;
        padding:0 20px;
    }
    @include tablet{
        &__about-section{
            padding:40px;
        }
        &__security-details{
            padding:20px 40px;
        }
        &__service-img{
            width:30%;
            height:100px;
            object-fit: cover;
            border-radius: 50%;
        }
        &__home-section{
            padding:40px 0;
            h3, h4{
                padding: 0 40px;
            }
        }
        &__fire-img{
            border-radius: initial;
            height:125px;
        }
        &__card-link{
            width:30%;
        }
        &__subtitle{
            padding:0 40px;
        }
    }

    @include desktop{
        &__first-img{
            height:550px;
        }
        &__title-box{
            top:250px;
        }
        &__title-main{
            margin-bottom: 40px;
            margin-top: -20px;
        }
        &__title{
            padding:20px 0;
            font-size: 38px;
        }
        &__about-section{
            padding:40px 8%;
        }
        &__about-info{
            width:50%;
        }
        &__security-details{
            padding:40px 8%;
        }
        &__section-info{
            width:50%;
            padding-top: 10px;
        }
        &__service-img{
            width:50%;
            height:150px;

        }
        &__services-box{
            margin:20px 0;

        }
        &__link{
            width:25%;
        }
        &__individual-service{
            margin:20px 0;
            align-items: flex-start;
        }
        &__home-section{
            padding:40px 8%;
            display: flex;
            flex-direction: row;
        }
        &__fire-right-section{
            margin-left: 40px;
        }
        &__fire-img{
            height:140px;
        }
        &__card{
            margin-top: 30px;
            margin-right: 10px;
        }
        &__card-link{
            width:30%;
        }
        &__fire-details{
            width:80%;
        }
        &__it-section{
            flex-wrap: nowrap;
            padding:100px;

        }
        &__it-services{
            padding:20px;
        }
        &__subtitle{
            padding:0 8%;
            width:40%;
        }
        &__home-img{
            width:450px;
            position: relative;
            bottom:20px;
            border-radius: 6px;
        }
    }
}