
@import '../../resources/media-queries.scss';
.blog{
    &__intro-section{
      
    }
    &__header{
        
    }
   
    &__title, &__subtitle{
        color:#130f36;
        text-align: center;
    }
    &__title{
        margin-bottom: 10px;
        font-size: 49px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        color: #051f61;
    }
    &__subtitle{
        font-size: 20px;
        line-height: 1.3;
        color: #051f61;
        padding-bottom: 30px;
    }
    &__article-section{
        margin:20px 0;
        text-decoration: none;
    }
    &__main-img{
        width:100%;
        height:260px;
        object-fit: cover;
    }
    &__section-info{
       margin:20px;
       padding-bottom: 20px;

    }
    &__article-type{
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        border:1px solid #130f36;
        padding:5px 10px;
        width:15%;
    }
    &__section-title{
        font-size: 20px;
        line-height: 1.6;
        color: #130f36;
    }
    &__section-details{
        font-size: 16px;
        font-weight: 300;
        line-height: 1.38;
        color: #5f6064;
        line-height: 1.63;
        max-height: 100px;
        overflow: hidden;
    }
    &__time-box{
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }
    &__time-icon{
        width:20px;
        height:20px;
        margin-right: 5px;
    }
    &__article-time{
        font-size: 14px;
        font-weight: 600;
        position: relative;
        color:#130f36;
    }

    &__loading{
        display: block;
        color:#130f36;
    }
    &__display-none{
        display: none;
    }
   @include tablet{
   
        &__container{
            padding:40px;
        }
        &__subscribe-logo{
            object-fit: cover;
        }
    }

    @include desktop{
        &__article-time{
            top:3px;
        }
        &__container{
            padding:60px 10% 20px;
        }
        &__header{
            padding:0 30px;
        }
        &__logo-image{
            height:80px;
            width:70px;
        }
        &__title, &__subtitle{
            text-align: left;
        }
        &__article-wrapper{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &__news-wrapper{
            width:30%;
        }
        &__featured-img{
            margin:0!important;
        }
        &__main-img{
            margin:0 20px;
        }
        &__subscribe-box {
            width:100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            height:250px;
            padding:40px 5%;
            margin:40px 0;
            h4{
                text-align: left;
            }
        }
        &__subscribe-info{
            width:50%;
            margin-top: 40px;
        }
        &__subscribe-title{
            font-size: 32px;
            text-align: left;
        }
        &__subscribe-subtitle{
            width:60%;
            text-align: left;
            margin:0;
        }
        &__form{
            width:40%;
            margin-top: 120px;
            flex-direction: row;
            justify-content: space-between;
        }
        &__subscribe-button{
           height:44px;
            margin-left: 10px;
            position: relative;
            bottom:20px;
            left:20px;
            width:130px;
        }
        &__subscribe{

        }
        &__field{
            height:40px;
            width:335px;
            cursor: pointer;
        }
        &__featured-article{
            display: flex;
            flex-direction: row;
            margin:60px 0 40px;
            border-bottom: 1px solid #434341;
            padding-bottom: 60px;
        }
        &__featured-img{
            width: 610px;
            height: 400px;
        }
        &__featured-section-info{
            margin-left: 100px;
        }
        &__featured-section-title{
            font-size: 36px;
            padding:20px 0;
        }
        &__featured-section-details{
            width:80%;
            padding-bottom: 10px;
        }
        &__subscribe-logo{
            width:60%;
            height:100%;
            object-fit: cover;
            left:initial;
        }
    }
}
