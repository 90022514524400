@import '../../resources/media-queries.scss';

.submenu{
    display: none;

@include desktop{
  width:50%;
    &__wrapper {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
        box-sizing: border-box;
        width: 18%;
        background: #00f9f7;
        border: 1px solid white;
        border-top: 3px solid white;
        border-radius: 20px;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 75px;
        z-index: 11;
        padding: 2% 0;
        height: auto;
    
    
        &.active {
          visibility: visible;
          opacity: 1;
        }
      }
      &__title{
        color:red;
        padding:5px 0;
      }
      &__links{
        list-style-type: none;
      }
      &__link{
        color:black;
        padding:10px 0!important;
      }
      &__link:hover{
        font-weight: bolder;
      }
}

}