@import '../../resources/media-queries.scss';

.contact{
    &__wrapper{
        padding:20px;
        background-color: #f8fcff;
    }
    &__form{
        display: flex;
        flex-direction: column;
        margin:10px 0;
    }
    &__subtitle{
        font-size: 24px;
        font-weight: bold;
        line-height: 1.88;
        color: #20252c;
        padding-top: 40px;
    }
    &__input{
        background-color: transparent;
        border:none;
        border-bottom:1px solid #535353;
        padding:10px;
        font-size: 16px;
        line-height: 1.5;
        color: #20252c;
        cursor: pointer;
    }
    &__label{
        font-size: 16px;
        line-height: 1.5;
        color: #20252c;
        text-align: left;
        padding:20px 0 5px;
    }
    &__text-area{
        height:80px;
    }
    &__submit-button{
        background-color: #ff0000;
        border:none;
        margin:20px 0;
    }
    &__button-writing{
        color:#00f9f7;
        padding:10px 0;
        font-size: 14px;
    }
    &__info-box{
        display: flex;
        flex-direction: row;
        margin:20px 0;
    }
    &__icon{
        width:24px;
    }
    &__detail{
        font-size: 18px;
        line-height: 1.56;
        color: #20252c;
        padding-left: 10px;
    }
    &__specific-info{
        font-weight: lighter;
        font-size: 18px;
        padding-top: 20px;
    }
    @include tablet{
        &__wrapper{
            padding:40px;
        }
        &__form{
            width:85%;
        }
    }

    @include desktop{
        &__wrapper{
            padding:40px 8%;
        }
        &__container{
            display: flex;
            flex-direction: row;
        }
        &__left-section{
            width:35%;
            margin-right: 180px;
        }
        &__info{
            padding-top: 20px;
        }
        &__specific-info{
            width:70%;
        }
    }
}