@import '../../resources/media-queries.scss';

.security{
    &__wrapper{
        padding:20px;
        background-color: #f8fcff;
    }
    &__title{
        color:#ff0000;
        font-size: 24px;
        line-height: 1.33;
        border-bottom: 1px solid #00f9f7;
        padding-bottom: 5px;
    }
    &__top-info{
        font-size: 18px;
        line-height: 1.33;
        color: #211919;
        padding-top: 20px;
    }
    &__info{
        font-size: 16px;
        line-height: 1.5;
        color: #211919;
        padding:10px 0;
    }
    &__bold{
        font-weight: bolder;
    }
    &__list{
        list-style-type: none;
        padding-left: 0;
        margin:10px 0;
    }
    &__list-item-box{
        display: flex;
        flex-direction: row;
    }
    &__list-img{
        width:18px;
        position: relative;
        bottom:2px;
    }
    &__list-item{
        font-size: 16px;
        color: #211919;
        padding:5px 0 5px 10px;
    }

    &__list-section {
      width: 100%;
    }
    &__subtitle{
        font-weight: 300;
        line-height: 1.41;
        color: #211919;
        padding-top: 20px;
    }
    &__list-types{
        list-style-image: url('/images/line.png');
        margin-left: 20px;
    }
    &__list-type{
        font-size: 16px;
        color: #211919;
        padding:5px 0 5px 10px;
    }
    &__acces-img{
        width:100%;
    }
    &__law-box{
        display: flex;
        flex-direction: row;
        padding:10px 0;
    }
    &__law-icon{
        width:20px;
        margin-right: 5px;
    }
    &__law-wrapper{
        margin:20px 0 0;
    }
    &__see-document{
        cursor: pointer;
        font-size: 18px;
    }
    &__renting-perks{
        font-weight: bold;

    }
    &__perks-box{
        display: flex;
        flex-direction: row;
    }
    &__check-icon{
        width:22px;
        margin-left: 10px;
    }
    &__renting-steps{
        display: flex;
        flex-direction: column;

    }
    &__renting-steps-box{
        display: flex;
        flex-direction: column;
        padding:20px 0;
        align-items: center;
    }
    &__step{
        font-size: 18px;
        line-height: 1.33;
        color: #211919;
        text-align: center;
    }
    &__renting-step-icon{
        width:200px;
        border-radius: 50%;
        object-fit: cover;
    }
    &__sublist-item{
       font-weight: lighter;
    }
    @include tablet{
        &__wrapper{
            padding:40px;
        }
        &__acces-img{
            width:100%;
        }
        &__renting-steps-box{
            align-items: initial;
        }
        &__step{
            text-align: initial;
            padding-top: 10px;
        }
    }

    @include desktop{
        &__wrapper{
            padding:60px 8% 20px;
        }
        &__title{
            width:40%;
            padding-bottom: 15px;
        }
        &__lower-section{
            display: flex;
            flex-direction: row;
            width:100%;
            justify-content: space-between;
        }
        &__section-img {
            width:60%;
            height:440px;
            object-fit: cover;
            border-radius: 5px;
            margin:10px 0 0 20px;
        }

        &__access-control-img {
          bottom: 0!important;
          width: auto!important;
        }
        &__flex-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__alarm-img{
            width:40%;
            height:600px;
        }
        &__acces-img{
            width:80%!important;
        }
        &__acces-top-info{
            width:80%;
        }
        &__accces-door-img{
           width:50%;
           height:600px;
           position: relative;
           bottom:110px;
        }
        &__analysis-wrapper{
            display: flex;
            flex-direction: row;
        }
        &__analysis-img{
            width:40%;
            object-fit: cover;
            margin-left: 60px;
        }

        &__law-container{
            display: flex;
            flex-direction: row;
        }
        &__renting-steps{
            flex-direction: row;
            padding-top: 20px;
        }
        &__gate-img{
            height:180px;
        }

        &__systems {
          width: 100%;
        }
    }
}
